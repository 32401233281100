.user {
  border: 2px solid black;
  border-radius: 10px;
  background-color: #b3e3ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 500px;
  height: 300px;
  margin: 10px;
}

.paginationBttns {
  width: 100%;
  height: 0px;
  position: relative;
  top: 35px;
  right: 15px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  background-color: #b3e3ff;
}

.paginationBttns a {
  background-color: #f3f4f6;
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  color: #2e2e2e;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #3c82f2;
}

.paginationActive a {
  color: white;
  background-color: #409fff;
}

@media screen and (max-width: 500px) {
  .nextBttn {
    display: none;
  }

  .previousBttn {
    display: none;
  }

  .paginationBttns a {
    margin: 6px;
  }
}
